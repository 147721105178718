import * as React from "react";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>404</title>
      <h1>404</h1>
    </main>
  );
};

export default NotFoundPage;
